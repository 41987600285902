import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['btn', 'simpleform', 'advancedform', 'template', 'templateContainer']

  connect() {
    this._templateContent = this.templateTarget.innerHTML
    this._state = 'simple'
    this._itemIndex = 0
  }

  toggle(event) {
    event.preventDefault()

    if(this._state == 'simple') {
      this._state = 'advanced'
      this.btnTarget.text = 'Căutare simplă'
      this.simpleformTarget.classList.add('d-none')
      this.advancedformTarget.classList.remove('d-none')
      this.advancedformTarget.requestSubmit()
      this.resetTemplates()
    } else {
      this._state = 'simple'
      this.btnTarget.text = 'Căutare avansată'
      this.simpleformTarget.classList.remove('d-none')
      this.advancedformTarget.classList.add('d-none')
      this.simpleformTarget.requestSubmit()
    }
  }

  resetTemplates() {
    this.templateContainerTarget.innerHTML = ''
    this.addItem()
  }

  addItem(event) {
    if(event) {
      event.preventDefault()
    }
    let content = this._templateContent.replaceAll('T_INDEX', this._itemIndex.toString())
    this.templateContainerTarget.insertAdjacentHTML('beforeend', content)
    this._itemIndex++
  }

  deleteItem(event) {
    event.preventDefault()
    let index = event.currentTarget.dataset.index

    document.querySelector(`#advancedCriteria_${index}`).remove()
  }
}
