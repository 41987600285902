// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@hotwired/turbo-rails")
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("local-time").start()
require("jquery")


// Start Rails UJS
window.Rails = require("@rails/ujs")
window.Rails.start()

// Stimulus
import "lib/observable"
import "lib/jquery_flot/flot"
import "lib/jquery_flot/flot.pie"
import "lib/jquery_flot/flot.pie.tooltip.min"
import "controllers"

// Bootstrap
import 'bootstrap'
import 'select2'

window.Chartist = require('chartist');


document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  });

  // @Andrei: nu functioneaza ca select2. N-am
  // reusit sa-l fac sa mearga.
  // @Ionut: Mai e valabila chestia asta?
  $('[data-toggle=select2]').select2({
    matcher: function(params, data) {
      // Do not display the item if there is no 'text' property
      if (typeof data.text === 'undefined') { return null; }

      // If there are no search terms, return all of the data
      if (params.term === '' || typeof params.term === 'undefined') {
          return data;
      } else {
          // `params.term` is the user's search term
          // `data.id` should be checked against
          // `data.text` should be checked against
          // console.log(params.term.toLowerCase());
          var q = params.term.toLowerCase();
          if (data.text.toLowerCase().indexOf(q) > -1 || data.id.toLowerCase().indexOf(q) > -1) {
              return $.extend({}, data, true);
          }
      }

      // Return `null` if the term should not be displayed
      return null;
    }
  });

  // @Andrei: nu folosim nicaieri functia de mai jos
  $('[data-toggle=tags_select2]').select2({
    placeholder: '',
    minimumInputLength: 1,
    tags: true,
    tokenSeparators: [','],
    width: '100%'
  });
})
